



$sprite:(
	url : '/assets/img/elements.png',
	fleche-gauche:(
		x:0,
		y:0,
		width:62px,
		height:118px
		),
	fleche-droite:(
		x:73px,
		y:0,
		width:62px,
		height:118px
		),
	point:(
		x:8px,
		y:134px,
		width:38px,
		height:38px
		),
	point-actif:(
		x:52px,
		y:134px,
		width:38px,
		height:38px
		),
	);


  $fleche-gauche : map-get($sprite,"fleche-gauche");


	$fleche-droite : map-get($sprite,"fleche-droite");

@mixin fleches-commun($w,$h,$ch,$url){
	$mt :  ($ch - $h) / 2;
	display: block;
	width: $w;
	height: $h;
	margin: auto;
	margin-top: $mt;
	background-image: url($url);
}

@mixin points($point,$url){
	float: left;
	width: map-get($point,"width");
	height: map-get($point,"height");
	background-image: url($url);
	background-position: - map-get($point,"x") (- map-get($point,"y"));
}



#content .photos {
	margin: 0;
	padding: 0;
	position: relative;
	max-width: $maxContentWidth;
	margin: 0 auto;



	.nav{
		opacity: .3;
		height: $photo-height;
		width: 100px;
		background-color: map-get($foundation-palette ,"primaire");
		position: absolute;
		cursor: pointer;
	}
	.nav:hover{
		opacity: .8;
	}
	.nav .fleche{
		@include fleches-commun(
			map-get($fleche-gauche,"width"),
			map-get($fleche-gauche,"height"),
			$photo-height,
			map-get($sprite,"url"));
	}
	.nav.gauche{
		.fleche{
			background-position: map-get($fleche-gauche,"x") map-get($fleche-gauche,"y");
		}
		left: 0px;
		z-index: 3;
	}
	.nav.droite{
		.fleche{
			background-position: - map-get($fleche-droite,"x") map-get($fleche-droite,"y");
		}
		top:0;
		right: 0px;
		z-index: 2;
	}

	.wrapper.points{
		$h:80px;
		$w:900px;
		background-color: #FFF;
		width: 900px;
		height: $h;
		position: relative;
		z-index: 4;
		top: $photo-height - $h;
		border-top-left-radius: 2em;
		border-top-right-radius: 2em;
		ul{
			overflow: hidden;
		}
		.point{
			@include points(map-get($sprite,"point"),map-get($sprite,"url"))
		}
		.point.actif{}
	}


	.carrouselPhoto.wrapper {
		height: $photo-height;
		overflow: hidden;
		.allPhotos {
			margin:0,auto;
			width: 100vw;
			position: absolute;
			height: $photo-height;
			.onePhoto {
				position: absolute;
				width: 100%;
				height: 100%;
				background-repeat: no-repeat;
				background-size: cover;
			}

/*
      //	What's that for ? images are in the markup
			.amplitude{
				background-image : url('/assets/img/large/002.jpg');
			}
			.puissance{
				background-image : url('/assets/img/large/_MG_2147.jpeg');
			}
			.performance{
				background-image : url('/assets/img/large/2012PR-9198.jpg');
			}
			.equilibre{
				background-image : url('/assets/img/large/3K4B8950.jpg');
			}
			.mobilite{
				background-image : url('/assets/img/large/chiendansleau.jpg');
			}
			.bienetre{
				background-image : url('/assets/img/large/3K4B6549.jpg');
			}
			.detente{
				background-image : url('/assets/img/large/_Y8Q1178.jpg');
			}
 */
    }
  }
}





.orbit {
  .orbit-caption {
		// opacity: 0.75; // !also fade the backgorund
		color: white;
		color: rgba(255, 255, 255, 0.6);
    font-size: 1.2rem;
    // padding: 1rem; // no need to keep this it is the default val
		// padding-left: 9.6vw;
    padding-left: 15%;
    line-height: 1.2;
    @media screen and (min-width: $tablet) {
      & { font-size: 2vw; }
    }
    @media screen and (min-width: $maxContentWidth) {
      & { font-size: 30px; }
    }
  }
}


.no-js {
	.orbit-container {
		overflow: hidden;
		@include aspect-ratio(100, 32, 'orbit-slide');
	}
}



// tiny no js fallback
// if nojs : display hide all iamges but the first
// I even wonder why foundation is not doing
// this already using css
.no-js #content .photos {
  .orbit-bullets,
  .orbit-next,
  .orbit-previous,
  .orbit-slide:not(.is-active),
  .orbit-slide:not(.is-active) .orbit-caption  {
    display: none;
  }
}
.js #content .photos {
  .orbit-bullets,
  .orbit-next,
  .orbit-previous,
  .orbit-slide:not(.is-active),
  .orbit-slide:not(.is-active) .orbit-caption  {
    display: inherit;
  }
}
