@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-visibility-classes;
@include foundation-grid;
@include foundation-orbit;
@include motion-ui-transitions;
@include motion-ui-animations;



/**
	* Breakpoints :
	*
	* assuming mobil first (which is foundations' case):
	* all media queries are only overwriting behaviour from
	* smaller screen : "when bigger than"
	*
	* naming rules:
	* those are to be used in
	* `and (min-width: $val)` declaration
	* under 1024 is mobile above is not mobile
	* mobil default 'orientation' is assumed portrait
	* above mobil default 'orientation' is assume landscape
	*
	*/
$mobileBig: 568px;
$tablet: 768px;
$tabletLandscape: 1024px;
$laptop: 1200px;

$maxContentWidth: 1440px;


/**
	*	Maintain aspect ratio mixin
	*
	*	@link https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/
	*
	*/
@mixin aspect-ratio($width, $height, $subSelector) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .#{$subSelector} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}



// Personnel
//--------------
$foundation-palette:(
	primaire : #FFF4F9,
	fonce : #890a4a,
	intermediaire : #CFB1C0,
	clair : #FFB8D5,
	secondaireFonce  : #558255,
	secondaireClair :#CBEDCB,
	tertnaireFonce: #2e1926,
	tertnaireFonceOverlay: rgba(46, 25, 38, 0.6)
	);
	$font-size : 16px;
	$photo-height:720px;



// let outline for keyboard Accessibility
// body[data-whatinput="keyboard"],
body[data-whatinput="touch"],
body[data-whatinput="mouse"] {
	* {
		outline: none;
	}
}

body,html {
	margin: 0;
	padding: 0;
	font-size : $font-size;

}
*{
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;


}
h3{
	padding: 1em;
	padding-left: 1em;
	font-size: 2em;
	margin: 0 auto;
	color:map-get($foundation-palette ,"fonce");
}
ul{
	padding-left:0;
	list-style: none !important;
}

#wrapper{
	background-color: white;
}

section:not(:first-of-type):not(:last-of-type) {
	margin-bottom: 32px;
	@media screen and (min-width: $mobileBig) {
		& { margin-bottom: 48px; }
	}
	@media screen and (min-width: $tablet) {
		& { margin-bottom: 64px; }
	}
	@media screen and (min-width: $laptop) {
		& { margin-bottom: 96px; }
	}
}


#content{
	background-color: map-get($foundation-palette ,"primaire");
	background-color: #fff;
}




// components
// -------------
@import 'components/header';
@import 'components/orbitSlider';
	// Orbit Overwrites
	@import 'foundation-components/orbit_overwrite';
	@include foundation-orbit;
@import 'components/formations';
@import 'components/consultations';
@import 'components/gMaps';
@import 'components/footer';



/*
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-menu;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;

@include motion-ui-transitions;
@include motion-ui-animations;
*/
