







/// Adds styles for an orbit slide caption. These styles are used on the `.orbit-caption` class.
@mixin orbit-caption {
  // default :
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: $orbit-caption-padding;
  color: foreground($orbit-caption-background);
  background-color: $orbit-caption-background;
  background-color:  map-get($foundation-palette ,"tertnaireFonceOverlay");



}
/// Adds base styles for the next/previous buttons in an Orbit slider. These styles are shared between the `.orbit-next` and `.orbit-previous` classes in the default CSS.
@mixin orbit-control {
  @include disable-mouse-outline;
  @include vertical-center;
  z-index: $orbit-control-zindex;
  padding: $orbit-control-padding;
  color: $white;
  opacity: 0.65;
  font-weight: 400;
  text-shadow: 0 0 8px rgba(0, 0, 0, .35),  0 0 3px rgba(0, 0, 0, .26);

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    opacity: 0.99;
    text-shadow: 0 0 18px rgba(0, 0, 0, .75),  0 0 8px rgba(0, 0, 0, .56);
    transition: opacity 0.3s ease-out;
  }
}


.orbit-image {
  margin: 0;
  width: 100%;
  max-width: 100%;

}

html.csstransforms .orbit-image {
  position: absolute;
  top: 50%;
  transform: translateY(-50%)
}


.orbit-next, .orbit-previous {

  position: absolute;

  user-select: none;
  height: 100%;
  font-size: 2em;
  html.csstransforms & {
    top: 50%;
    transform: translateY(-50%) !important;
  }
  @media screen and (min-width: $tablet) {
    & {
      font-size: 4rem;
      padding: 2rem !important;
    }
  }
  // font-size: calc(100vw * 0.12);
}

