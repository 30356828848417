

footer {
  display: none;
  text-align: center;
  padding: 32px 0;
  background-color:  map-get($foundation-palette ,"tertnaireFonce");
  color: #fff;

  h4 {
    margin-bottom: 16px;
    font-size: 1.4em;
  }
  a {
    color: white;
  }

  // if flex is available (mdrnzr html class)
	// use it to make things beautifully aligned
	@media screen and (min-width: $tablet) {
		.flexbox & .row {
			display: flex;
			align-items: center;
		}
	}
  .row > ul, .row figure {
    margin-bottom: 32px;
  } 
  ul.navigation li {
    display: block;
    width: 100%;
  }
  figure {
    margin: 0;
    text-align: center;
    img {
      width: 100%;
      height: auto;
      max-width: 140px;
    }
  }
}
