.lieuxconsultation {
	background-color: white;
	padding: 1em 0;
	margin-bottom: 48px;
	max-width: $maxContentWidth;
	margin: 0 auto;
	.adresses {
		padding: 32px;
	}
	li.adresseconsult {
		min-height: 10em;
		h5 {
			font-size: 1.5em;
			color: map-get($foundation-palette , "fonce");
		}
	}
  a {
    color: inherit;
  }
	@media screen and (min-width: $tablet) {
		& {
			margin-bottom: 96px;
		}
	}
}

/**
 * Ovewrite the defautl grid behaviour
 * which is .column:last-child:not(:first-child) {
 *   float: right;
 * }
 */
.column:last-child:not(:first-child) {
  float: left;
}

.carte {
  text-align: center;
}
