

header {
	background-color: map-get($foundation-palette ,"fonce");
	color: white !important;
	width: 100%;
	overflow: hidden;
	.header.wrapper {
		width: 100%;
	}

  h1, h2, h3 {
    margin: 0;
    text-align: center;
  }
	h1 {
		margin-top: 16px;
		padding: 0;
		font-size: 2em;
		font-family: 'Cinzel';
		font-weight: 100;
		font-size: 1.4rem;
		font-size: 7vw;
	}
	h2 {
		margin-bottom: 24px;
		font-size: 1.1em;
		font-size: 4.5vw;
		font-weight: 400;
	}
	@media screen and (min-width: $mobileBig) {
  	& {
  		h1{ font-size: 2.45rem; }
  		h2{ font-size: 1.6rem; }
  	}
  }

	h3 {
		font-size: 1em;
	}
	nav {
		width: 100%;
		background-color: map-get($foundation-palette ,"intermediaire");
		background-color: #fff;

		ul.menu {
			padding: .4em .1em;
			li.columns {
				font-size: 1.5em;
				text-align:center;
			}
			a{
				text-transform: capitalize !important;
				color: map-get($foundation-palette ,"fonce");
        text-decoration: underline;
        }
			}
		}
}