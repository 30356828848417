.formations {
	background-color: map-get($foundation-palette ,"fonce");
	color: #FFF;
	.row {
		padding-bottom:  1em;
	}
	@media screen and (min-width: $tablet) {
		& {
			padding: 32px;
		}
	}

	h3 {
		color: white;
	}
  a {
    color: inherit;
  }
	ul{
		max-width: $maxContentWidth
	}
	@media screen and (min-width: $tablet) {
		.flexbox & .row {
			display: flex;
			align-items: flex-end;
		}
	}

	li {
		padding: inherit;
		// padding: 6px;
		span {
			font-weight: bold;
			font-size: 120%;
			display: block;
		}
	}
	figure {
		margin: 0;
    padding: 16px;
    padding-bottom: 0;
    text-align: center;
		height: 100%;
		img {
			width: 100%;
			height: auto;
			max-width: 130px;
		}
	}
}

